/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "@ionic/angular/css/core.css";
@import '@angular/material/prebuilt-themes/indigo-pink.css';
@import '~mapbox-gl/dist/mapbox-gl.css';

/* Basic CSS for apps built with Ionic */
@import "@ionic/angular/css/normalize.css";
@import "@ionic/angular/css/structure.css";
@import "@ionic/angular/css/typography.css";
@import '@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "@ionic/angular/css/padding.css";
@import "@ionic/angular/css/float-elements.css";
@import "@ionic/angular/css/text-alignment.css";
@import "@ionic/angular/css/text-transformation.css";
@import "@ionic/angular/css/flex-utils.css";
// @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&display=swap');
// @import "@fontsource/poppins"; // Defaults to weight 400
// @import "@fontsource/poppins/400.css"; // Specify weight
// @import "@fontsource/poppins/400-italic.css"; // Specify weight and style

@import "swiper/scss";
@import "swiper/scss/navigation";
@import "swiper/scss/pagination";

@tailwind base;
@tailwind components;
@tailwind utilities;
body {
    // font-family: 'Poppins', sans-serif !important;
}
.page-container{
    // font-family: 'Poppins', sans-serif !important;
}
.title-font {
    font-size: 21px;
    font-weight: 500;
    padding: 25px;
}
.main-theme-button{
    background: black !important;
    color: beige;
}
.text-center{
    text-align: center !important;
}



swiper-container {
    --swiper-pagination-bullet-inactive-color: var(--ion-color-step-200, #cccccc);
    --swiper-pagination-color: var(--ion-color-primary, #3880ff);
    --swiper-pagination-progressbar-bg-color: rgba(var(--ion-text-color-rgb, 0, 0, 0), 0.25);
    --swiper-scrollbar-bg-color: rgba(var(--ion-text-color-rgb, 0, 0, 0), 0.1);
    --swiper-scrollbar-drag-bg-color: rgba(var(--ion-text-color-rgb, 0, 0, 0), 0.5);
  }
  
  swiper-slide {
    display: flex;
    position: relative;
  
    flex-direction: column;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
  
    width: 100%;
    height: 100%;
  
    font-size: 18px;
  
    text-align: center;
    box-sizing: border-box;
  }
  
  swiper-slide img {
    width: auto;
    max-width: 100%;
    height: auto;
    max-height: 100%;
  }
  ion-card {
    margin: 0px; /* Adjust the margin as needed to reduce space */
  }

  .custom-subtitle {
    font-family: 'Poppins', sans-serif; /* Use the Poppins font */
    /* Add any other custom styles you need */
  }

  .custom-datetime {
    // Set the desired background color here
    // --background: #ffffff; /* Replace with your preferred background color */
    --background: #f1f1f1;
  }
  .custom-datetime.dark {
    // Set the desired background color here
    // --background: #ffffff; /* Replace with your preferred background color */
    // --background: #f1f1f1;
  }

  .subtitle-header {
    padding-top: 6px;
    padding-bottom: 10px;
    padding-left: 10px
  }

  .img-border-radius {
    border-radius:8px;
  }

  .calendar-size {
    transform: scale(0.9);
  }

  ion-datetime {
    transform: scale(0.9); /* Adjust the font size as needed */
    /* Add any other styles you want to customize */
  }

  ion-fab-button::part(native) {
    background-color:white;
    border-radius: 35px;
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.3), 0px 1px 3px 1px rgba(0, 0, 0, 0.15);
    color: black;
    transform: scale(0.9);
  }
  
  ion-fab-button::part(native):hover::after {
    background-color:white;
  }
  
  ion-fab-button::part(native):active::after {
    background-color:white;
  }

  

  /*
 * Optional CSS
 * -----------------------------------
 */

/* This sets a different background and item background in light mode on ios */
.ios body {
   --ion-background-color: white;
  --ion-toolbar-background: var(--ion-background-color);
  --ion-item-background: #ffffff;
  --ion-icon-color: black; /* Default color */
  --ion-icon-color-dark: white; /* Color for dark mode */
}

/* This sets a different background and item background in light mode on md */
.md body {
  --ion-background-color:white;
  --ion-toolbar-background: var(--ion-background-color);
  --ion-item-background: white;
}

/* This is added for the flashing that happens when toggling between themes */
ion-item {
  --transition: none;
}

.transparent-button {
  --background: transparent;
  --color: black; /* You can set the text color here as well */
}

.bold{
  font-weight: bold;
}

.red-dot {
  width: 6px;
  height: 6px;
  background-color: red;
  border-radius: 50%;
  display: inline-block;
  margin-left: 10px;
  position: relative;
  top: 3px;
}
.red-circle {
  width: 7px;
  height: 7px;
  background-color: red;
  border-radius: 50%;
  display: inline-block;
  right: 4px;
  position: relative;
  bottom: 6px;
}
.no-text{
  text-align: center;
  position: relative;
  top: 5%;
}

ion-popover::part(backdrop) {
  --backdrop-opacity: 0.6;
}

.trip-image {
  flex: 0 0 auto;
  margin-right: 10px; /* Adjust margin for spacing between image and details */
  width: 100px; /* Set the width to match the size of the parent container */
  height: 100px; /* Set the height to match the size of the parent container */
  overflow: hidden; /* Hide any overflowing content within the image container */
  margin-bottom: 14px;
}

.trip-image img {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensure the image fills the container without stretching */
}

ion-item {
  --padding-start: 0;
  --padding-end: 0;
  // --border-width: 0;
  --background: none;
  --box-shadow: none;
  --inner-border-width: 0;
  /* Add more styles as needed */
}
ion-item-divider {
  --border-style: none;
}

/* Custom styling for ion-item-options */
ion-item-options {
  --ion-item-option-background: transparent;
  /* Add more styles as needed */
}

/* Custom styling for ion-item-option */
ion-item-option {
  /* Add more styles as needed */
}

/* Hide the inner item of ion-radio, which is the part that gives it the Ionic look */
ion-radio::part(container) {
  display: none;
}

/* Style the host of ion-radio to look like a native radio button */
ion-radio {
  height: 16px;
  width: 16px;
  border: 2px solid #d5d5d5; /* Border color */
  border-radius: 50%;
  display: inline-block;
  position: relative;
  margin: 0 5px;
  --background: #ffffff; /* Background color */
}

/* When ion-radio is checked, add a dot in the center */
ion-radio::part(marker) {
  display: block;
  height: 8px;
  width: 8px;
  background: #000000; /* Dot color */
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* Adjust the styles when the ion-radio is checked */
ion-radio[aria-checked="true"] {
  border: 2px solid #000000; /* Border color when checked */
}

.user-icon {
  display: inline-block;
  position: absolute;
  width: 26px;
  height: 24px;
  border-radius: 50%;
  text-align: center;
   line-height: 22px;
  color: white;
  border: solid 0.8px;
  left: 2px;
  
}

.lottie-splash-screen {
  position: fixed; /* or 'absolute' */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  background-color: white; /* Or any color that matches your splash background */
}


.special-date .mat-calendar-body-cell-content {
  background-color: #007680;
  border-radius: 50%;
  color: white;
}

.avatar-group {
  display: flex;
  flex-direction: row;
  align-items: center;
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(255, 0, 0, 0.7); /* Changed to red */
    background: linear-gradient(to top, rgb(50 46 46) 0%, rgba(0, 0, 0, 0) 100%);
  }
  70% {
    box-shadow: 0 0 0 10px rgba(255, 0, 0, 0); /* Changed to red */
    background: linear-gradient(to top, rgb(50 46 46) 0%, rgba(0, 0, 0, 0) 100%);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(255, 0, 0, 0); /* Changed to red */
    background: linear-gradient(to top, rgb(50 46 46) 0%, rgba(0, 0, 0, 0) 100%);
  }
}

.live-icon {
  animation: pulse 2s infinite;
  color: #FF0000; /* Changed to red */
  border-radius: 50%;
  background: linear-gradient(to top, rgb(50 46 46) 0%, rgba(0, 0, 0, 0) 100%);
}

.listing-container {
  font-family: 'Poppins', sans-serif;
}

// .listing-image {
//   position: relative;
// }


.md .tabs-bottom {
  transform: translateY(-56px) !important;
}
.ios .tabs-bottom {
  transform: translateY(-50px) !important;
}
.md .header-top {
  top: 56px !important;
}
.ios .header-top {
  top: 44px !important;
}


 
.content-container {
  padding-top: 7px;
  text-align: left;
}

.info-label {
  font-weight: bold; /* If you want the label to be bold */
}

.description {
  font-size: 17px;
  margin-bottom: 10px;
  color: #444444;
}

.best-times,
.popular-cities-title {
  margin-top: 10px;
  font-size: 15px;
}

.cities-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  gap: 10px;
  margin-top: 10px;
}

.city {
  background: #f0f0f0;
  text-align: center;
  padding: 5px;
  border-radius: 10px;
}


.page-text {
  // font-family: 'Poppins', sans-serif;
 font-size: 16px;
  color: black;
  font-weight: 300; /* Light or Regular weight */
  // letter-spacing: 0.02em; /* Adjust as needed */
  // -webkit-font-smoothing: antialiased;
  // -moz-osx-font-smoothing: grayscale;
}




ion-searchbar {
  --background: #ffffff;
  border: 1px solid #cccccc;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 20px; /* Add border radius */
}
.search-bar-disable {
  --background: #ffffff;
  --border-radius: 20px; /* Rounded corners */
  // --border: 1px solid #cccccc; /* Border */
  --box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1); /* Shadow */
  --padding-start: 8px; /* Padding on the left */
  --padding-end: 8px; /* Padding on the right */
  --padding-top: 4px; /* Padding on the top */
  --padding-bottom: 4px; /* Padding on the bottom */
  --min-height: 40px; /* Minimum height */
  display: flex;
  align-items: center;
  margin: auto; /* Center the item */
  width: 90%; /* Set the width you prefer */
  height: 40px;
  background: white;
}

/* Overriding with higher specificity */
html body .swiper-container .swiper-3d {
  perspective: none;
}

// swiper-container {
//   --swiper-pagination-bullet-inactive-color: var(--ion-color-step-200, #cccccc);
//   --swiper-pagination-color: var(--ion-color-primary, #0054e9);
//   --swiper-pagination-progressbar-bg-color: rgba(var(--ion-text-color-rgb, 0, 0, 0), 0.25);
//   --swiper-scrollbar-bg-color: rgba(var(--ion-text-color-rgb, 0, 0, 0), 0.1);
//   --swiper-scrollbar-drag-bg-color: rgba(var(--ion-text-color-rgb, 0, 0, 0), 0.5);
// }

// swiper-slide {
//   display: flex;
//   position: relative;

//   flex-direction: column;
//   flex-shrink: 0;
//   align-items: center;
//   justify-content: center;

//   width: 100%;
//   height: 100%;

//   font-size: 18px;

//   text-align: center;
//   box-sizing: border-box;
// }

// swiper-slide img {
//   width: auto;
//   max-width: 100%;
//   height: auto;
//   max-height: 100%;
// }
.sobolious{ 
  font-weight: 500;
}
.rating {
  display: flex;
  align-items: center;
  
  ion-icon {
    color: var(--ion-color-warning, #ffce00); // Or any color you want for the stars
  }
  ion-label {
    margin-left: 0.25em;
  }
}

.theme-color {
  color: black;
  font-weight: bold;
}

.red {
  color: red !important
}

.card-title-location h2 {
  font-size: 1.1rem;
  font-weight: bold;
  margin-bottom: 0.25rem;
  text-align: start;
  color: black;
}

.voted {
  --ion-color-primary: rgb(192, 192, 192); /* Change green to whatever color you prefer */
}

.not-voted {
  --ion-color-primary:#0881dd;; /* Change gray to whatever color you prefer */
}

